import axios from "axios";
import {Complaint, ComplaintStatusEnum} from "../pages/ComplaintsPage/Complaint";

export const fetchComplaints = async (
  limit: number,
  status: ComplaintStatusEnum,
  nextPageToken?: string | null,
  search?: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/complained_users?search=${
      search ?? ""
    }`,
    {status, cursor: {next_page_token: nextPageToken, limit: limit}},
    {
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};

export const changeComplaintStatus = async (data: Complaint, status: ComplaintStatusEnum) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/complained_users/process`,
    {
      id: data.id,
      status
    },
    {
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};