import axios from "axios";
import {Comment} from "../pages/CommentsPage/Comment";

export const fetchComments = async (
  limit: number,
  nextPageToken?: string | null,
  search?: string
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/comment/fetch_all`,
    {
      params:{pageToken: nextPageToken, itemsPerPage: limit},
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};

export const deleteComment = async (id: Comment['ext_id']) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/comment`,
    {
      params: {extID: id},
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};