import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { Test } from "./Test";
import DeleteDialog from "../../components/DeleteDialog";
import {
  addNewExamQuestion,
  fetchTest,
  removeExamQuestion,
  updateExamQuestion,
} from "../../api";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import AddModal from "../../components/AddModal";

const TestsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<Test | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: Test) => {
    setCurrentdata(data);
    setEditModalOpen(true);
  };

  const handleRemoveClick = (data: Test) => {
    setCurrentdata(data);
    setRemoveModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setRemoveModalOpen(false);
    setAddModalOpen(false);
  };
  const handleAdd = (updateddata: Test) => {
    if (!updateddata) return;
    addTest.mutate(updateddata);
    handleClose();
  };

  // Handler for when the modal save action is performed
  const handleSave = (updateddata: Test) => {
    // console.log("handleSave", updateddata);
    changeTest.mutate(updateddata);
    setEditModalOpen(false);
  };

  const handleConfirmRemove = () => {
    if (!currentdata) return;
    deleteTest.mutate({ id: currentdata.id });
    setRemoveModalOpen(false);
  };

  const columns: Column<Test>[] = [
    {
      label: "id",
      render: (test) => <>{test.id}</>,
    },
    {
      label: "Вопрос",
      render: (test) => <>{test.problem}</>,
    },
    {
      label: "Количество ответов",
      render: (test) => <>{test.answers?.length}</>,
    },
    // {
    //   label: "Дата",
    //   render: (test) => <>{test.date.toLocaleDateString()}</>,
    // },
    // {
    //   label: "Статус",
    //   render: (test) => <>{test.status}</>,
    // },
    {
      label: "Действия",
      render: (test) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(test)}
            key={test.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleRemoveClick(test)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["tests", rowsPerPage, nextPageToken],
    queryFn: () => fetchTest(rowsPerPage, nextPageToken),
  });
  const addTest = useMutation({
    mutationFn: addNewExamQuestion,
    mutationKey: ["tests"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["tests"] }),
  });
  const changeTest = useMutation({
    mutationFn: updateExamQuestion,
    mutationKey: ["tests"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["tests"] }),
  });
  const deleteTest = useMutation({
    mutationFn: removeExamQuestion,
    mutationKey: ["tests"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["tests"] }),
  });
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Тесты</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}>
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>
      {data?.data && (
        <DataTable
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          columns={columns}
          data={data.data.questions}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data.data.num_elements}
        />
      )}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить Вопрос"
        columns={[
          { label: "Вопрос", key: "problem", editable: true },
          { key: "answers", label: "Правильно", type: "array", editable: true },
        ]}
        defaultState={{ answers: [], problem: "" }}
        onSave={handleAdd}
      />
      {currentdata && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование теста"
          data={currentdata}
          columns={[
            { label: "Название", key: "problem", editable: true },
            { label: "Ответы", key: "answers", type: "array", editable: true },
          ]}
          onSave={handleSave}
        />
      )}
      {currentdata && (
        <DeleteDialog
          open={isRemoveModalOpen}
          onClose={handleClose}
          onConfirm={handleConfirmRemove}
          title="Удаление теста"
          text={`Вы точно хотите удалить тест
        ${currentdata.problem}?`}
        />
      )}
    </div>
  );
};

export default TestsPage;
