import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import AuthLayout from "./layouts/AuthLayout";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import UsersPage from "./pages/UsersPage/UsersPage";
import ArticlesPage from "./pages/ArticlesPage/ArticlesPage";
import LawsPage from "./pages/LawsPage/LawsPage";
import ForumsPage from "./pages/ForumsPage/ForumsPage";
import EventsPage from "./pages/EventsPage/EventsPage";
import TestsPage from "./pages/TestsPage/TestsPage";
import NotificationsPage from "./pages/NotificationsPage/NotificationsPage";
import StopWordsPage from "./pages/StopWordsPage/StopWordsPage";
import ComplaintsPage from "./pages/ComplaintsPage/ComplaintsPage";
import AdminsPage from "./pages/AdminsPage/AdminsPage";
import ApplicationsPage from "./pages/ApplicationsPage/ApplicationsPage";
import { AuthProvider } from "./providers/AuthProvider";
import LoginPage from "./pages/LoginPage/LoginPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import FAQPage from "./pages/FAQPage/FAQPage";
import SubjectsPage from "./pages/SubjectsPage/SubjectsPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import { CommentsPage } from "./pages/CommentsPage/CommentsPage";
import OrganizationsPage from "./pages/OrganizationsPage/OrganizationsPage";

const theme = createTheme({
  palette: {
    primary: {
      light: "#e5e5e5",
      main: "#7a7a7a",
      dark: "#6a6a6a",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
const queryClient = new QueryClient();
function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/" element={<AuthLayout />}>
                <Route path="/" element={<MainPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/subjects" element={<SubjectsPage />} />
                <Route path="/articles" element={<ArticlesPage />} />
                <Route path="/laws" element={<LawsPage />} />
                <Route path="/forums" element={<ForumsPage />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/applications" element={<ApplicationsPage />} />
                <Route path="/comments" element={<CommentsPage />} />
                <Route path="/quizzes" element={<TestsPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/banned-words" element={<StopWordsPage />} />
                <Route path="/complaints" element={<ComplaintsPage />} />
                <Route path="/admins" element={<AdminsPage />} />
                <Route path="/organizations" element={<OrganizationsPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
