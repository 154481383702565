export function parseAdminRole(role: string) {
  switch (role) {
    case "superadmin":
      return "Суперадмин";
    case "trusted":
      return "Доверенный";
    case "standard":
      return "Стандартный";
    default:
      return role;
  }
}
