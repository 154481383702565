import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { Forum, ForumRequest, Media, Post } from "./Forum";
import DeleteDialog from "../../components/DeleteDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewForum,
  fetchForums,
  fetchSubjects,
  removeForum,
  updateForum,
  updateForumStatus,
} from "../../api";
import { Subject } from "../SubjectsPage/Subject";
import AddModal from "../../components/AddModal";
import { STATUSES } from "./constants";

const ForumsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEditStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<Forum | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: Forum) => {
    setCurrentdata(data);
    setEditModalOpen(true);
  };
  const handleEditStatusClick = (data: Forum) => {
    setCurrentdata(data);
    setEditStatusModalOpen(true);
  };

  const handleRemoveClick = (data: Forum) => {
    setCurrentdata(data);
    setRemoveModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setRemoveModalOpen(false);
    setAddModalOpen(false);
    setEditStatusModalOpen(false);
  };

  // Handler for when the modal save action is performed
  const handleSave = (updateddata: Post) => {
    if (!updateddata) return;
    updateddata.subject_id = subjects?.find(
      (subject: Subject) => subject.text === updateddata.subject_id
    )?.id;
    const attachmentIndexes = [...(updateddata.attachments ?? [])].map(
      (_, index) => ({
        attachment_index: index,
      })
    );
    updateddata.media = [
      ...(attachmentIndexes ?? []),
      ...(updateddata.media ?? []),
    ];
    changeForum.mutate(updateddata);
    setEditModalOpen(false);
  };

  const handleSaveStatus = (updateddata: Forum) => {
    if (!updateddata || !updateddata.post.ext_id) return;

    changeForumStatus.mutate({
      ext_id: updateddata.post.ext_id,
      status:
        STATUSES.find((status) => status.label === updateddata.status)?.value ??
        "rejected",
    });
    setEditModalOpen(false);
  };
  const handleAdd = (updateddata: ForumRequest) => {
    updateddata.subject_id = subjects?.find(
      (subject: Subject) => subject.text === updateddata.subject_id
    )?.id;
    const attachmentIndexes = [...(updateddata.attachments ?? [])].map(
      (_, index) => ({
        attachment_index: index,
      })
    );
    updateddata.media = [...(attachmentIndexes ?? [])];
    addForum.mutate(updateddata);
    handleClose();
  };

  const handleConfirmRemove = () => {
    if (!currentdata) return;
    deleteForum.mutate({ ext_id: currentdata.post.ext_id });
    setRemoveModalOpen(false);
  };

  const columns: Column<Forum>[] = [
    {
      label: "id",
      render: (forum) => <>{forum.post.id}</>,
    },
    {
      label: "Название",
      render: (forum) => <>{forum.post.title}</>,
    },
    {
      label: "Автор",
      render: (forum) => <>{forum.post.author?.nickname}</>,
    },
    {
      label: "Дата создания",
      render: (forum) => (
        <>
          {new Date(forum.post.created_at ?? "").toLocaleDateString()}{" "}
          {new Date(forum.post.created_at ?? "").toLocaleTimeString()}
        </>
      ),
    },
    {
      label: "Статус",
      render: (forum) => (
        <>
          {STATUSES.find((status) => status.value === forum.status)?.label ??
            "rejected"}
        </>
      ),
    },
    {
      label: "Медиа",
      render: (forum) => (
        <>
          {forum.post?.media?.map((media: Media) => (
            <img src={media.src} alt={media.type} style={{ width: "100px" }} />
          ))}
        </>
      ),
    },
    {
      label: "Действия",
      render: (forum) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(forum)}
            key={forum.post.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="status"
            onClick={() => handleEditStatusClick(forum)}
            key={forum.post.id + "status"}
          >
            <PlaylistAddCheckIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleRemoveClick(forum)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ["forums", rowsPerPage, nextPageToken],
    queryFn: () => fetchForums(rowsPerPage, nextPageToken),
  });

  const { data: subjects } = useQuery({
    queryKey: ["subjects"],
    queryFn: () => fetchSubjects(),
  });

  const addForum = useMutation({
    mutationFn: addNewForum,
    mutationKey: ["forums"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["forums"] }),
  });

  const deleteForum = useMutation({
    mutationFn: removeForum,
    mutationKey: ["forums"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["forums"] }),
  });

  const changeForum = useMutation({
    mutationFn: updateForum,
    mutationKey: ["forums"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["forums"] }),
  });

  const changeForumStatus = useMutation({
    mutationFn: updateForumStatus,
    mutationKey: ["forums"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["forums"] }),
  });

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Форумы</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}></div>
      {data && (
        <DataTable
          columns={columns}
          data={data.data}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(_event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data?.num_count}
        />
      )}
      {currentdata && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование форума"
          data={{
            ...currentdata.post,
            subject_id: currentdata.post.subject?.text,
          }}
          columns={[
            { label: "Название", key: "title", editable: true },
            { label: "Текст", key: "text", editable: true },
            {
              label: "Тема",
              key: "subject_id",
              editable: true,
              type: "select",
              options: subjects?.map((subject: Subject) => subject.text) ?? [],
            },
            {
              label: "Файлы",
              key: "attachments",
              editable: true,
              type: "file",
            },
            {
              label: "Медиа",
              key: "media",
              editable: true,
              type: "order",
            },
          ]}
          onSave={handleSave}
        />
      )}
      {currentdata && (
        <EditModal
          open={isEditStatusModalOpen}
          onClose={handleClose}
          title="Редактирование статуса форума"
          data={{
            ...currentdata,
            status:
              STATUSES.find((status) => status.value === currentdata.status)
                ?.label ?? "rejected",
          }}
          columns={[
            {
              label: "Статус",
              key: "status",
              editable: true,
              type: "select",
              options: STATUSES.map((status) => status.label),
            },
          ]}
          onSave={handleSaveStatus}
        />
      )}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить форум"
        defaultState={{
          title: "",
          text: "",
          subject_id: "",
        }}
        columns={[
          {
            label: "Тема",
            key: "subject_id",
            editable: true,
            type: "select",
            options: subjects?.map((subject: Subject) => subject.text) ?? [],
          },
          {
            label: "Файлы",
            key: "attachments",
            editable: true,
            type: "file",
          },
        ]}
        onSave={handleAdd}
      />
      <DeleteDialog
        open={isRemoveModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmRemove}
        title="Удаление форума"
        text={`Вы точно хотите удалить форум
        ${currentdata?.post.title}?`}
      />
    </div>
  );
};

export default ForumsPage;
