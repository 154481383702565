import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { Law } from "./Law";
import DeleteDialog from "../../components/DeleteDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addNewLaw, fetchLaws, removeLaw, updateLaw } from "../../api";
import { Subject } from "../SubjectsPage/Subject";
import AddModal from "../../components/AddModal";
import { fetchSubjects } from "../../api";
import { Media } from "../ArticlesPage/Article";

// Dummy user data - replace with actual data fetching logic
const LawsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<Law | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: Law) => {
    setCurrentdata({ ...data, subject_id: data.subject.text });
    setEditModalOpen(true);
  };

  const handleRemoveClick = (data: Law) => {
    setCurrentdata(data);
    setRemoveModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setRemoveModalOpen(false);
    setAddModalOpen(false);
  };

  // Handler for when the modal save action is performed
  const handleSave = (updateddata: Law) => {
    updateddata.subject_id = subjects?.find(
      (subject: Subject) => subject.text === updateddata.subject_id
    )?.id;
    const attachmentIndexes = [...(updateddata.attachments ?? [])].map(
      (_, index) => ({
        attachment_index: index,
      })
    );
    updateddata.media = [
      ...(attachmentIndexes ?? []),
      ...(updateddata.media ?? []),
    ];
    changeLaw.mutate(updateddata);
    handleClose();
  };

  const handleAdd = (updateddata: Law) => {
    updateddata.subject_id = subjects?.find(
      (subject: Subject) => subject.text === updateddata.subject_id
    )?.id;
    const attachmentIndexes = [...(updateddata.attachments ?? [])].map(
      (_, index) => ({
        attachment_index: index,
      })
    );
    updateddata.media = [...(attachmentIndexes ?? [])];
    addLaw.mutate(updateddata);
    handleClose();
  };

  const handleConfirmRemove = () => {
    if (!currentdata) return;
    deleteLaw.mutate({ ext_id: currentdata.ext_id });
    handleClose();
  };

  const columns: Column<Law>[] = [
    {
      label: "id",
      render: (law) => <>{law.id}</>,
    },
    {
      label: "Название",
      render: (law) => <>{law.title}</>,
    },
    {
      label: "Автор",
      render: (law) => <>{law.author.nickname}</>,
    },
    {
      label: "Дата",
      render: (law) => <>{new Date(law.created_at).toLocaleDateString()}</>,
    },
    {
      label: "Медиа",
      render: (law) => (
        <>
          {law.media.map((media: Media) => (
            <img src={media.src} alt={media.type} style={{ width: "100px" }} />
          ))}
        </>
      ),
    },
    {
      label: "Действия",
      render: (law) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(law)}
            key={law.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton aria-label="block" onClick={() => handleRemoveClick(law)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ["articles", rowsPerPage, nextPageToken],
    queryFn: () => fetchLaws(rowsPerPage, nextPageToken),
  });

  const { data: subjects } = useQuery({
    queryKey: ["subjects"],
    queryFn: () => fetchSubjects(),
  });

  const addLaw = useMutation({
    mutationFn: addNewLaw,
    mutationKey: ["articles"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["articles"] }),
  });

  const deleteLaw = useMutation({
    mutationFn: removeLaw,
    mutationKey: ["articles"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["articles"] }),
  });

  const changeLaw = useMutation({
    mutationFn: updateLaw,
    mutationKey: ["articles"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["articles"] }),
  });

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Законодательные инициативы</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}>
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>
      {data && (
        <DataTable
          columns={columns}
          data={data.data}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data?.num_count}
        />
      )}{" "}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить Законодательную инициативу"
        columns={[
          { label: "Название", key: "title", editable: true },
          { label: "Текст", key: "text", editable: true },
          {
            label: "Тема",
            key: "subject_id",
            editable: true,
            type: "select",
            options: subjects?.map((subject: Subject) => subject.text) ?? [],
          },
          { label: "Файл", key: "attachments", editable: true, type: "file" },
        ]}
        onSave={handleAdd}
      />
      {currentdata && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование законодательной инициативы"
          data={{ ...currentdata, subject_id: currentdata.subject.text }}
          columns={[
            { label: "Название", key: "title", editable: true },
            { label: "Текст", key: "text", editable: true },
            {
              label: "Тема",
              key: "subject_id",
              editable: true,
              type: "select",
              options: subjects?.map((subject: Subject) => subject.text) ?? [],
            },
            {
              label: "Файлы",
              key: "attachments",
              editable: true,
              type: "file",
            },
            {
              label: "Медиа",
              key: "media",
              editable: true,
              type: "order",
            },
          ]}
          onSave={handleSave}
        />
      )}
      {currentdata && (
        <DeleteDialog
          open={isRemoveModalOpen}
          onClose={handleClose}
          onConfirm={handleConfirmRemove}
          title="Удаление закона"
          text={`Вы точно хотите удалить Законодательную инициативу
        ${currentdata.title}?`}
        />
      )}
    </div>
  );
};

export default LawsPage;
