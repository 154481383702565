import axios from "axios";
import { FAQ, FAQRequest } from "../pages/FAQPage/FAQ";

const fetchFaq = async (limit: number, nextPageToken?: string | null) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/faq/get`,
    { cursor: { next_page_token: nextPageToken, limit: limit } },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewFaq = async (data: FAQRequest) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/faq`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const updateFaq = async (data: FAQ) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/faq`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const removeFaq = async (id: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/faq/${id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export { fetchFaq, addNewFaq, removeFaq, updateFaq };
