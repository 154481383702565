import axios from "axios";
import { Article } from "../pages/ArticlesPage/Article";

const fetchArticles = async (limit: number, nextPageToken?: string | null) => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/feed/article/fetch?itemsPerPage=${limit}&PageToken=${
      nextPageToken ?? ""
    }`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewArticle = async (data: Article) => {
  console.log("Data:", data);
  const response = await axios.postForm(
    `${process.env.REACT_APP_BACKEND_URL}/article`,
    { ...data, media: JSON.stringify(data.media) },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};

const updateArticle = async (
  data: Pick<
    Article,
    "ext_id" | "title" | "text" | "subject_id" | "attachments" | "media"
  >
) => {
  const response = await axios.putForm(
    `${process.env.REACT_APP_BACKEND_URL}/article`,
    { ...data, media: JSON.stringify(data.media) },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};

const removeArticle = async (data: Pick<Article, "ext_id">) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/article`,
    {
      data: { ext_id: data.ext_id },
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export { fetchArticles, addNewArticle, removeArticle, updateArticle };
