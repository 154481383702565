import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AddModal from "../../components/AddModal";
import { Subject } from "./Subject";
import {
  addNewSubject,
  fetchSubjects,
  removeSubject,
  updateSubject,
} from "../../api";
import DeleteDialog from "../../components/DeleteDialog";

const SubjectsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isBanModalOpen, setBanModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState<Subject | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const handleEditClick = (userData: Subject) => {
    setCurrentData(userData);
    setEditModalOpen(true);
  };

  const handleBanClick = (userData: Subject) => {
    setCurrentData(userData);
    setBanModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setBanModalOpen(false);
    setAddModalOpen(false);
  };

  // Handler for when the modal save action is performed
  const handleSave = (updatedData: Subject) => {
    changeUser.mutate(updatedData);
    setEditModalOpen(false);
  };
  const handleConfirmBan = () => {
    if (!currentData) return;
    deleteUser.mutate(currentData.id);
    setBanModalOpen(false);
  };

  const handleAdd = (updateddata: Subject) => {
    addUser.mutate(updateddata);
    handleClose();
  };
  const columns: Column<Subject>[] = [
    {
      label: "id",
      render: (subject) => <>{subject.id}</>,
    },
    {
      label: "Тема",
      render: (subject) => <>{subject.text}</>,
    },
    {
      label: "Создан",
      render: (subject) => <>{subject.created_at}</>,
    },
    {
      label: "Действия",
      render: (subject) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(subject)}
            key={subject.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleBanClick(subject)}
          >
            <BlockIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["subjects"],
    queryFn: () => fetchSubjects(),
  });
  const addUser = useMutation({
    mutationFn: addNewSubject,
    mutationKey: ["subjects"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["subjects"] }),
  });
  const deleteUser = useMutation({
    mutationFn: removeSubject,
    mutationKey: ["subjects"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["subjects"] }),
  });
  const changeUser = useMutation({
    mutationFn: updateSubject,
    mutationKey: ["subjects"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["subjects"] }),
  });
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Темы</h1>
      <div style={{ marginBottom: "20px" }}>
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>
      {data && (
        <DataTable
          columns={columns}
          data={data}
         
        />
      )}{" "}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить вопрос"
        defaultState={{
          text: "",
          id: 0,
        }}
        columns={[{ label: "Тема", key: "text", editable: true }]}
        onSave={handleAdd}
      />
      {currentData && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование вопроса"
          data={currentData}
          columns={[{ label: "Тема", key: "text", editable: true }]}
          onSave={handleSave}
        />
      )}
      <DeleteDialog
        open={isBanModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmBan}
        title="Удаление темы"
        text={`Вы точно хотите удалить тему
        ${currentData?.text}?`}
      />
    </div>
  );
};

export default SubjectsPage;
