import { Outlet, useNavigate } from "react-router-dom";
import DrawerComponent from "./components/DrawerComponent";
import Header from "./components/Header";
import { useContext, useEffect } from "react";
import { AuthContext } from "../providers/AuthProvider";

const AuthLayout = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    document.title = "Оружейник";
  }, [isAuthenticated, navigate]);
  return (
    <>
      <Header />
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          flexGrow: 1,
          minHeight: " 100vh",
        }}
      >
        <DrawerComponent />
        <div style={{ flexGrow: 1 }}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
