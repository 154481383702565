import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/joy/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { Admin } from "./Admin";
import DeleteDialog from "../../components/DeleteDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewAdmin,
  fetchAdminRoles,
  fetchAdmins,
  removeAdmin,
  updateAdmin,
} from "../../api";
import AddModal from "../../components/AddModal";
import { parseAdminRole } from "./lib";

const AdminsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [isBanModalOpen, setBanModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<Admin | null>(null);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: Admin) => {
    setCurrentdata(data);
    setEditModalOpen(true);
  };

  const handleBanClick = (data: Admin) => {
    setCurrentdata(data);
    setBanModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setAddModalOpen(false);
    setBanModalOpen(false);
  };

  // Handler for when the modal save action is performed
  const handleSave = (updateddata: Admin) => {
    editAdmin.mutate(updateddata);
    handleClose();
  };
  const handleAdd = (data: Admin) => {
    addAdmin.mutate(data);
    handleClose();
  };
  const handleConfirmBan = () => {
    deleteAdmin.mutate(currentdata?.ext_id!);
    handleClose();
  };

  const columns: Column<Admin>[] = [
    {
      label: "id",
      render: (admin) => <>{admin.id}</>,
    },
    {
      label: "Логин",
      render: (admin) => <>{admin.login}</>,
    },
    {
      label: "Роль",
      render: (admin) => <>{parseAdminRole(admin.role)}</>,
    },
    {
      label: "Действия",
      render: (admin) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(admin)}
            key={admin.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton aria-label="block" onClick={() => handleBanClick(admin)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["admins", nextPageToken],
    queryFn: () => fetchAdmins(20, nextPageToken),
  });
  const { data: roles, isLoading: rolesLoading } = useQuery({
    queryKey: ["adminRoles"],
    queryFn: () => fetchAdminRoles(),
  });

  const addAdmin = useMutation({
    mutationFn: addNewAdmin,
    mutationKey: ["admins"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["admins"] }),
  });
  const editAdmin = useMutation({
    mutationFn: updateAdmin,
    mutationKey: ["admins"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["admins"] }),
  });
  const deleteAdmin = useMutation({
    mutationFn: removeAdmin,
    mutationKey: ["admins"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["admins"] }),
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Администраторы</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}>
        {rolesLoading ? (
          <CircularProgress />
        ) : (
          <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
        )}{" "}
      </div>
      <DataTable
        count={data.data.num_elements}
        columns={columns}
        data={data.data.admins}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(event) => {
          setRowsPerPage(+event.target.value);
        }}
        page={page}
        handleChangePage={(event, newPage) => {
          setPage(newPage);
          if (newPage > page) {
            setNextPageToken(data.next_page_token);
            setTokens((prev) => [...prev, data.next_page_token]);
          } else {
            setNextPageToken(tokens[newPage - 1]);
          }
        }}
      />{" "}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить администратора"
        columns={[
          { label: "Логин", key: "login", editable: true },
          {
            label: "Роль",
            key: "role",
            editable: true,
            type: "select",
            options: roles ?? [],
          },
          { label: "Пароль", key: "password", editable: true },
        ]}
        onSave={handleAdd}
      />
      {currentdata && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование администратора"
          data={currentdata}
          columns={[
            {
              label: "Роль",
              key: "role",
              editable: true,
              type: "select",
              options: roles ?? [],
            },
            { label: "Пароль", key: "password", editable: true },
          ]}
          onSave={handleSave}
        />
      )}
      {currentdata && (
        <DeleteDialog
          open={isBanModalOpen}
          onClose={handleClose}
          onConfirm={handleConfirmBan}
          title="Удаление админа"
          text={`Вы точно хотите удалить админа
        ${currentdata.login}?`}
        />
      )}
    </div>
  );
};

export default AdminsPage;
