import React, { useContext } from "react";
import { AppBar, Toolbar, IconButton, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { AuthContext } from "../../providers/AuthProvider";

const Header = () => {
  const { logout } = useContext(AuthContext);
  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* Icon on the left */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          {/* <MenuIcon /> */}
          <img src="/logo.png" alt="" style={{ maxHeight: "48px" }} />
        </IconButton>

        {/* Spacer to push the next element to the right */}
        <div style={{ flexGrow: 1 }}></div>

        {/* Button on the right */}
        <Button color="inherit" onClick={logout}>
          <LogoutIcon />
          Выйти
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
