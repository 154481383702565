import axios, { AxiosError } from "axios";
import { createContext, useState } from "react";

interface IAuthContext {
  isAuthenticated: boolean;
  user: any;
  login: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => Promise<void>;
  error: string | null;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: any) => {
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("authToken")
  );
  const [user, setUser] = useState<any>(null);

  const login = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admins/login`,
        {
          username,
          password,
        }
      );

      // Assuming the token is in the headers
      const token = response.data["token"];
      console.log("Token:", token);
      if (token) {
        localStorage.setItem("authToken", token);
      }
      setIsAuthenticated(true);
      setUser(response.data);
    } catch (error) {
      console.error("Login failed:", error);
      if ((error as AxiosError).response?.status === 401) {
        setError("Неверный логин или пароль");
      } else setError((error as AxiosError).message);
    }
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("authToken");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
