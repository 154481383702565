import axios from "axios";
import { Test } from "../pages/TestsPage/Test";

const fetchTest = async (limit: number, nextPageToken?: string | null) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/get_exam_questions`,
    { cursor: { next_page_token: nextPageToken, limit: limit } },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewExamQuestion = async (data: Test) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/add_exam_question`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const updateExamQuestion = async (data: Test) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/admins/update_exam_question`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const removeExamQuestion = async (data: Pick<Test, "id">) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/admins/delete_exam_question/${data.id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export {
  fetchTest,
  addNewExamQuestion,
  updateExamQuestion,
  removeExamQuestion,
};
