import axios from "axios";
import { Subject } from "../pages/SubjectsPage/Subject";

const fetchSubjects = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/subject/all`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const addNewSubject = async (data: Pick<Subject, "text">) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/subject`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const updateSubject = async (data: Pick<Subject, "text" | "id">) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/subject`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const removeSubject = async (id: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/subject`,
    {
      data: { id: id },
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export { fetchSubjects, addNewSubject, removeSubject, updateSubject };
