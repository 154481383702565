export type Complaint = {
  id: number
  "user_id": string,
  "nickname": string,
  "complained_user_id": string,
  "complained_nickname": string,
  "status": ComplaintStatusEnum,
  "created_at": string
};

export enum ComplaintStatusEnum {
  APPROVED = 'approved',
  CREATED = 'created',
  REJECTED = 'rejected',
  ALL = 'all'
}