import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { Article, Media } from "./Article";
import { Subject } from "../SubjectsPage/Subject";
import DeleteDialog from "../../components/DeleteDialog";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import {
  addNewArticle,
  fetchArticles,
  removeArticle,
  updateArticle,
} from "../../api";
import AddModal from "../../components/AddModal";
import { fetchSubjects } from "../../api";

const ArticlesPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<Article | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: Article) => {
    setCurrentdata({ ...data, subject_id: data.subject.text });
    setEditModalOpen(true);
  };

  const handleRemoveClick = (data: Article) => {
    setCurrentdata(data);
    setRemoveModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setRemoveModalOpen(false);
    setAddModalOpen(false);
  };

  // Handler for when the modal save action is performed

  const handleSave = (updateddata: Article) => {
    updateddata.subject_id = subjects?.find(
      (subject: Subject) => subject.text === updateddata.subject_id
    )?.id;
    const attachmentIndexes = [...(updateddata.attachments ?? [])].map(
      (_, index) => ({
        attachment_index: index,
      })
    );
    updateddata.media = [
      ...(attachmentIndexes ?? []),
      ...(updateddata.media ?? []),
    ];
    // console.log(updateddata);
    changeArticle.mutate(updateddata);
    handleClose();
  };

  const handleAdd = (updateddata: Article) => {
    updateddata.subject_id = subjects?.find(
      (subject: Subject) => subject.text === updateddata.subject_id
    )?.id;
    const attachmentIndexes = [...(updateddata.attachments ?? [])].map(
      (_, index) => ({
        attachment_index: index,
      })
    );
    updateddata.media = [...(attachmentIndexes ?? [])];
    updateddata.voting = {
      text: updateddata.votingTitle ?? "",
      answers: (updateddata.voting as unknown as string[])?.map((answer) => ({
        text: answer,
      })),
    };
    // console.log(updateddata);
    addArticle.mutate(updateddata);
    handleClose();
  };

  const handleConfirmRemove = () => {
    if (!currentdata) return;
    deleteArticle.mutate({ ext_id: currentdata.ext_id });
    handleClose();
  };

  const columns: Column<Article>[] = [
    {
      label: "id",
      render: (article) => <>{article.id}</>,
    },
    {
      label: "Название",
      render: (article) => <>{article.title}</>,
    },
    {
      label: "Автор",
      render: (article) => <>{article.author.nickname}</>,
    },
    {
      label: "Дата",
      render: (article) => (
        <>{new Date(article.created_at).toLocaleDateString()}</>
      ),
    },
    {
      label: "Медиа",
      render: (article) => (
        <>
          {article.media.map((media: Media) => (
            <img src={media.src} alt={media.type} style={{ width: "100px" }} />
          ))}
        </>
      ),
    },
    {
      label: "Действия",
      render: (article) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(article)}
            key={article.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleRemoveClick(article)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ["articles", rowsPerPage, nextPageToken],
    queryFn: () => fetchArticles(rowsPerPage, nextPageToken),
  });

  const { data: subjects } = useQuery({
    queryKey: ["subjects"],
    queryFn: () => fetchSubjects(),
  });

  const addArticle = useMutation({
    mutationFn: addNewArticle,
    mutationKey: ["articles"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["articles"] }),
  });

  const deleteArticle = useMutation({
    mutationFn: removeArticle,
    mutationKey: ["articles"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["articles"] }),
  });

  const changeArticle = useMutation({
    mutationFn: updateArticle,
    mutationKey: ["articles"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["articles"] }),
  });

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Статьи</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}>
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>
      {data && (
        <DataTable
          columns={columns}
          data={data.data}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data?.num_count}
        />
      )}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить Статью"
        columns={[
          { label: "Название", key: "title", editable: true },
          { label: "Текст", key: "text", editable: true },
          {
            label: "Тема",
            key: "subject_id",
            editable: true,
            type: "select",
            options: subjects?.map((subject: Subject) => subject.text) ?? [],
          },
          {
            label: "Файлы",
            key: "attachments",
            editable: true,
            type: "file",
            defaultValue: [],
          },
          {
            label: "Голосование",
            key: "votingTitle",
            editable: true,
          },
          {
            label: "Ответ",
            key: "voting",
            editable: true,
            type: "arrayWithout",
            defaultValue: [],
          },
        ]}
        onSave={handleAdd}
      />
      {currentdata && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование статьи"
          data={currentdata}
          columns={[
            { label: "Название", key: "title", editable: true },
            { label: "Текст", key: "text", editable: true },
            {
              label: "Тема",
              key: "subject_id",
              editable: true,
              type: "select",
              options: subjects?.map((subject: Subject) => subject.text) ?? [],
            },
            {
              label: "Файлы",
              key: "attachments",
              editable: true,
              type: "file",
            },
            {
              label: "Медиа",
              key: "media",
              editable: true,
              type: "order",
            },
          ]}
          onSave={handleSave}
        />
      )}

      {currentdata && (
        <DeleteDialog
          open={isRemoveModalOpen}
          onClose={handleClose}
          onConfirm={handleConfirmRemove}
          title="Удаление статьи"
          text={`Вы точно хотите удалить стаью
        ${currentdata.title}?`}
        />
      )}
    </div>
  );
};

export default ArticlesPage;
