import React, {useState} from "react";
import {Button, CircularProgress, IconButton} from "@mui/material";
import {DataTable} from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {Column} from "../../types/Column";
import EditModal from "../../components/EditModal";
import {CreatedEvent, EditableEvent, EventType} from "./EventType";
import DeleteDialog from "../../components/DeleteDialog";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createEvent, deleteEvent, fetchEvents, getEditableObject, updateEvent} from "../../api/events";
import AddModal from "../../components/AddModal";
import {Subject} from "../SubjectsPage/Subject";
import {fetchSubjects} from "../../api";

export const typeOptions: { label: string, value: string }[] = [
  {label: 'Праздники', value: 'holiday'},
  {label: 'Соревнования', value: 'competitions'},
  {label: 'Выставки', value: 'exhibition'},
  {label: 'События', value: 'event'}
]

export const levelsOptions: { label: string, value: string }[] = [
  {value: 'federal', label: 'Федеральное'},
  {value: 'district', label: 'Окружное'},
  {value: 'region', label: 'Региональное'},
  {value: 'local', label: 'Местное'}
]

const EventsPage = () => {
  const queryClient = useQueryClient();

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<EventType | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: EventType) => {
    setCurrentdata(data);
    setEditModalOpen(true);
  };

  const handleRemoveClick = (data: EventType) => {
    setCurrentdata(data);
    setRemoveModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setAddModalOpen(false)
    setEditModalOpen(false);
    setRemoveModalOpen(false);
  };

  const handleSave = (updateddata: EditableEvent) => {
    updateddata.subject_id = subjects?.find(
      (subject: Subject) => subject.text === String(updateddata.subject_id)
    )?.id;
    const levelValue = levelsOptions.find(option => updateddata.level === option.label)
    const typeValue = typeOptions.find(option => updateddata.type === option.label)
    updateddata.type = typeValue?.value ?? ""
    updateddata.level = levelValue?.value ?? ""

    handleUpdateEvent.mutate(updateddata)
    setEditModalOpen(false);
  };

  const handleAddNewEvent = (newEvent: CreatedEvent) => {
    newEvent.subject_id = subjects?.find(
      (subject: Subject) => subject.text === String(newEvent.subject_id)
    )?.id;
    const levelValue = levelsOptions.find(option => newEvent.level === option.label)
    const typeValue = typeOptions.find(option => newEvent.type === option.label)
    newEvent.type = typeValue?.value ?? ""
    newEvent.level = levelValue?.value ?? ""

    handleCreateEvent.mutate(newEvent)
    setAddModalOpen(false);
  }

  const columns: Column<EventType>[] = [
    {
      label: "Название",
      render: (event) => <>{event.post.title}</>,
    },
    {
      label: "Дата",
      render: (event) => <>{new Date(event.start_date).toLocaleDateString()}</>,
    },
    {
      label: "Тип",
      render: (event) => <>{typeOptions.find(option => option.value === event.type)?.label}</>,
    },
    {
      label: "Действия",
      render: (event) => (
        <>
          <IconButton
            onClick={() => handleEditClick(event)}
            key={event?.post?.ext_id}
          >
            <EditIcon/>
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleRemoveClick(event)}
          >
            <DeleteIcon/>
          </IconButton>
        </>
      ),
    },
  ];

  const {data, error, isLoading} = useQuery({
    queryKey: ["events", rowsPerPage, nextPageToken],
    queryFn: () => fetchEvents(rowsPerPage, nextPageToken),
  });

  const {data: subjects} = useQuery({
    queryKey: ["subjects"],
    queryFn: () => fetchSubjects(),
  });

  const handleDeleteEvent = useMutation({
    mutationFn: (id: string) => deleteEvent(id),
    mutationKey: ["events"],
    onSettled: () => queryClient.invalidateQueries({queryKey: ["events"]}),
  });

  const handleUpdateEvent = useMutation({
    mutationFn: (payload: EditableEvent) => updateEvent(payload),
    mutationKey: ["events"],
    onSettled: () => queryClient.invalidateQueries({queryKey: ["events"]}),
  });

  const handleCreateEvent = useMutation({
    mutationFn: (payload: CreatedEvent) => createEvent(payload),
    mutationKey: ["events"],
    onSettled: () => queryClient.invalidateQueries({queryKey: ["events"]}),
  });

  const handleConfirmRemove = () => {
    if (currentdata) {
      handleDeleteEvent.mutate(currentdata?.post?.ext_id)
    }
    setRemoveModalOpen(false);
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{paddingLeft: "20px", paddingRight: "10px"}}>
      <h1>События календаря</h1>
      <div style={{marginBottom: "20px", display: "flex"}}>
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>
      {isLoading ? <CircularProgress/> : <DataTable
        columns={columns}
        data={data?.data ?? []}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(event) => {
          setRowsPerPage(+event.target.value);
        }}
        page={page}
        handleChangePage={(event, newPage) => {
          setPage(newPage);
          if (newPage > page) {
            setNextPageToken(data.next_page_token);
            setTokens((prev) => [...prev, data.next_page_token]);
          } else {
            setNextPageToken(tokens[newPage - 1]);
          }
        }}
        count={data.num_elements}
      />}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить событие"
        columns={[
          {label: "Название", key: "title", editable: true},
          {label: "Текст", key: "text", editable: true},
          {
            label: "Дата начала",
            key: "start_date",
            editable: true,
            type: "datetime",
          },
          {
            label: "Дата окончания",
            key: "end_date",
            editable: true,
            type: "datetime",
          },
          {
            label: "Тип",
            key: "type",
            editable: true,
            type: "select",
            options: typeOptions.map(option => option.label)
          },
          {
            label: "Уровень",
            key: "level",
            editable: true,
            type: "select",
            options: levelsOptions.map(option => option.label)
          },
          {
            label: "Тема",
            key: "subject_id",
            editable: true,
            type: "select",
            options: subjects?.map((subject: Subject) => subject.text) ?? [],
          },
          {label: "Файл", key: "attachments", editable: true, type: "file"},
        ]}
        onSave={handleAddNewEvent}
      />
      {currentdata && <EditModal
          open={isEditModalOpen && !!currentdata}
          onClose={handleClose}
          title="Редактирование события"
          data={getEditableObject(currentdata!) as EditableEvent}
          columns={[
            {label: "Название", key: "title", editable: true},
            {label: "Текст", key: "text", editable: true},
            {
              label: "Дата начала",
              key: "start_date",
              editable: true,
              type: "datetime",
            },
            {
              label: "Дата окончания",
              key: "end_date",
              editable: true,
              type: "datetime",
            },
            {
              label: "Тип",
              key: "type",
              editable: true,
              type: "select",
              options: typeOptions.map(option => option.label)
            },
            {
              label: "Уровень",
              key: "level",
              editable: true,
              type: "select",
              options: levelsOptions.map(option => option.label)
            },
            {
              label: "Тема",
              key: "subject_id",
              editable: true,
              type: "select",
              options: subjects?.map((subject: Subject) => subject.text) ?? [],
            },
          ]}
          onSave={handleSave}
      />}
      <DeleteDialog
        open={isRemoveModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmRemove}
        title="Удаление события"
        text={`Вы точно хотите удалить событие календаря
        ${currentdata?.post?.title}?`}
      />
    </div>
  );
};

export default EventsPage;
