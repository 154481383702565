import axios from "axios";
import { Notification } from "../pages/NotificationsPage/Notification";

const fetchNotifications = async (
  limit: number,
  nextPageToken?: string | null
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/notifications/get`,
    { cursor: { next_page_token: nextPageToken, limit: limit } },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewNotification = async (data: Notification) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/notifications`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const updateNotification = async (data: Notification) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/notifications`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const removeNotification = async (data: Pick<Notification, "id">) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/notifications/${data.id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export {
  fetchNotifications,
  addNewNotification,
  updateNotification,
  removeNotification,
};
