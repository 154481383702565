import axios from "axios";
import { Admin } from "../pages/AdminsPage/Admin";

const fetchAdmins = async (limit: number, next_page_token?: string | null) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/get_admins`,
    { cursor: { next_page_token: next_page_token, limit: limit } },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const fetchAdminRoles = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/admins/get_admins_roles`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewAdmin = async (
  admin: Pick<Admin, "login" | "role" | "password">
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/add_admin`,
    admin,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const updateAdmin = async (
  admin: Pick<Admin, "role" | "password" | "ext_id">
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/admins/update_admin/${admin.ext_id}`,
    admin,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const removeAdmin = async (id: number | string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/admins/delete_admin/${id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export { fetchAdmins, addNewAdmin, fetchAdminRoles, removeAdmin, updateAdmin };
