import React, { useState } from "react";
import { TextField, IconButton, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { Notification } from "./Notification";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import {
  addNewNotification,
  fetchNotifications,
  updateNotification,
  removeNotification,
} from "../../api/notifications";
import AddModal from "../../components/AddModal";
import { getFormattedDatetimeWithoutZnadT } from "../../util/formatDateForInput";
import { fetchArticles } from "../../api";
import { Article } from "../ArticlesPage/Article";
import DeleteDialog from "../../components/DeleteDialog";

const NotificationsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<Notification | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: Notification) => {
    setCurrentdata(data);
    setEditModalOpen(true);
  };

  const handleRemoveClick = (data: Notification) => {
    setCurrentdata(data);
    setDeleteModalOpen(true);
  };

  function handleAdd(data: Notification) {
    addNotification.mutate({
      ...data,
      created_at: getFormattedDatetimeWithoutZnadT(),
      notificated_at: getFormattedDatetimeWithoutZnadT(data.notificated_at),
      content_id: articles.data?.find(
        (article: Article) => article.title === data.content_id
      )?.ext_id,
      receiver: "all",
    });
    handleClose();
  }
  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setAddModalOpen(false);
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    deleteNotification.mutate({
      ...currentdata,
    });
    handleClose();
  };

  // Handler for when the modal save action is performed
  const handleSave = (updateddata: Notification) => {
    changeNotification.mutate(updateddata);
    handleClose();
  };

  const columns: Column<Notification>[] = [
    {
      label: "id",
      render: (notification) => <>{notification.id}</>,
    },
    {
      label: "Заголовок",
      render: (notification) => <>{notification.header}</>,
    },
    {
      label: "Текст",
      render: (notification) => <>{notification.text}</>,
    },
    {
      label: "Дата",
      render: (notification) => (
        <>{new Date(notification.created_at ?? "").toLocaleDateString()}</>
      ),
    },
    {
      label: "Отправлено",
      render: (notification) => (
        <>{new Date(notification.notificated_at ?? "").toLocaleDateString()}</>
      ),
    },
    {
      label: "Действия",
      render: (notification) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(notification)}
            key={notification.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleRemoveClick(notification)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const { data: articles } = useQuery({
    queryKey: ["articles", rowsPerPage, nextPageToken],
    queryFn: () => fetchArticles(rowsPerPage, nextPageToken),
  });

  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ["notification", rowsPerPage, nextPageToken],
    queryFn: () => fetchNotifications(rowsPerPage, nextPageToken),
  });

  const addNotification = useMutation({
    mutationFn: addNewNotification,
    mutationKey: ["notification"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["notification"] }),
  });

  const deleteNotification = useMutation({
    mutationFn: removeNotification,
    mutationKey: ["notification"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["notification"] }),
  });

  const changeNotification = useMutation({
    mutationFn: updateNotification,
    mutationKey: ["notification"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["notification"] }),
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Рассылки</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}>
        <TextField
          id="search-user"
          label="Поиск"
          variant="outlined"
          size="small"
          style={{ marginRight: "8px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>{" "}
      {data && (
        <DataTable
          columns={columns}
          data={data.data.notifications}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data?.data.num_elements}
        />
      )}
      {currentdata && articles.data && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование уведомления"
          data={{
            ...currentdata,
            content_id: articles.data?.find(
              (article: Article) => article.ext_id === currentdata.content_id
            )?.title,
          }}
          columns={[
            { label: "Текст", key: "text", editable: true },
            { label: "Заголовок", key: "header", editable: true },
            {
              label: "Содержимое",
              key: "content_id",
              editable: true,
              type: "select",
              options:
                articles.data?.map((article: Article) => article.title) ?? [],
            },
            {
              label: "Дата",
              key: "notificated_at",
              editable: true,
              type: "datetime",
            },
            {
              label: "Transition",
              key: "transition",
              editable: true,
            },
            {
              label: "Получатель",
              key: "receiver",
              editable: true,
            },
          ]}
          onSave={handleSave}
        />
      )}
      {currentdata && (
        <DeleteDialog
          open={isDeleteModalOpen}
          onClose={handleClose}
          title="Удаление уведомления"
          text={`Вы точно хотите удалить уведомление
        ${currentdata.text}?`}
          onConfirm={handleDelete}
        />
      )}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Создать рассылку"
        defaultState={{
          text: "",
          header: "",
          content_id: "",
          notificated_at: new Date(),
          transition: "",
          receiver: "",
        }}
        columns={[
          { label: "Текст", key: "text", editable: true },
          { label: "Заголовок", key: "header", editable: true },
          {
            label: "Содержимое",
            key: "content_id",
            editable: true,
            type: "select",
            options:
              articles?.data?.map((article: Article) => article.title) ?? [],
          },
          {
            label: "Дата",
            key: "notificated_at",
            editable: true,
            type: "datetime",
          },
        ]}
        onSave={handleAdd}
      />
    </div>
  );
};

export default NotificationsPage;
