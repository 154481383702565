export default function MainPage() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src="/logo.png" alt="logo" style={{ maxHeight: "480px" }} />
      <h1>Оружейник</h1>
    </div>
  );
}
