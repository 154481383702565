import axios from "axios";
import { StopWord } from "../pages/StopWordsPage/StopWord";

const fetchStopWords = async (limit: number, nextPageToken?: string | null) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/stopword/fetch`,
    { cursor: { next_page_token: nextPageToken, limit: limit } },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewStopWord = async (data: StopWord[]) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/stopword`,
    { stop_word: data },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const updateStopWord = async (data: StopWord) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/stopword`,
    { stop_word: [data] },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const removeStopWord = async (data: Pick<StopWord, "id">) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/stopword`,
    {
      data: { ids: [data.id] },
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export { fetchStopWords, addNewStopWord, updateStopWord, removeStopWord };
