import axios from "axios";
import { User } from "../pages/UsersPage/User";

const fetchUsers = async (
  limit: number,
  nextPageToken?: string | null,
  search?: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/get_users?search=${
      search ?? ""
    }`,
    { cursor: { next_page_token: nextPageToken, limit: limit } },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewUser = async (data: User) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admins/add_user`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const updateUser = async (data: User) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/users/update_user/${data.ext_id}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const removeUser = async (data: Pick<User, "ext_id">) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/users/delete_user`,
    {
      data: { ext_id: data.ext_id },
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export { fetchUsers, addNewUser, removeUser, updateUser };
