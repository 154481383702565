import PeopleIcon from "@mui/icons-material/People";
import ArticleIcon from "@mui/icons-material/Article";
import GavelIcon from "@mui/icons-material/Gavel";
import ForumIcon from "@mui/icons-material/Forum";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CommentIcon from "@mui/icons-material/Comment";
import QuizIcon from "@mui/icons-material/Quiz";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import FlagIcon from "@mui/icons-material/Flag";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BlockIcon from "@mui/icons-material/Block";
import ReportIcon from "@mui/icons-material/Report";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

export const routes = [
  { icon: <PeopleIcon />, label: "Пользователи", href: "/users" },
  { icon: <ArticleIcon />, label: "Статьи", href: "/articles" },
  { icon: <GavelIcon />, label: "Законы", href: "/laws" },
  { icon: <ForumIcon />, label: "Форумы", href: "/forums" },
  { icon: <EventNoteIcon />, label: "События календаря", href: "/events" },
  {
    icon: <EventNoteIcon />,
    label: "Заявки на участие в событии",
    href: "/applications",
  },
  { icon: <CommentIcon />, label: "Комментарии", href: "/comments" },
  { icon: <QuizIcon />, label: "Тесты", href: "/quizzes" },
  { icon: <LiveHelpIcon />, label: "FAQ", href: "/faq" },
  { icon: <FlagIcon />, label: "Темы", href: "/subjects" },

  { icon: <NotificationsIcon />, label: "Рассылки", href: "/notifications" },
  { icon: <BlockIcon />, label: "Стоп-слова", href: "/banned-words" },
  { icon: <ReportIcon />, label: "Жалобы", href: "/complaints" },
  {
    icon: <AdminPanelSettingsIcon />,
    label: "Администраторы",
    href: "/admins",
  },
  {
    icon: <AdminPanelSettingsIcon />,
    label: "Организации",
    href: "/organizations",
  },
];
