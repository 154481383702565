export const formatDateForInput = (date: string | number | Date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export function getFormattedDatetimeWithoutZnadT(
  date?: string | number | Date
): string {
  const now = date ? new Date(date) : new Date();

  const pad = (num: number): string => num.toString().padStart(2, "0");
  const padMilliseconds = (num: number): string =>
    num.toString().padStart(3, "0");

  const year = now.getFullYear();
  const month = pad(now.getMonth() + 1);
  const day = pad(now.getDate());
  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());
  const seconds = pad(now.getSeconds());
  const milliseconds = padMilliseconds(now.getMilliseconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}
