import React, { useState } from "react";
import { DataTable } from "../../components/DataTable";
import { Column } from "../../types/Column";
import { Application } from "./Application";
import { useQuery } from "@tanstack/react-query";
import { fetchEventApplication } from "../../api/eventApplication";
import { CircularProgress } from "@mui/material";

const ApplicationsPage = () => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const columns: Column<Application>[] = [
    {
      label: "Автор",
      render: (application) => <>{application.author.nickname}</>,
    },
    {
      label: "Номер автора",
      render: (application) => <>{application.author.phone}</>,
    },
    {
      label: "Событие",
      render: (application) => <>{application.event_title}</>,
    },
    {
      label: "Дата создания",
      render: (application) => (
        <>{new Date(application.created_at).toLocaleDateString()}</>
      ),
    },
  ];

  const { data, error, isLoading } = useQuery({
    queryKey: ["eventAplications", rowsPerPage, nextPageToken],
    queryFn: () => fetchEventApplication(rowsPerPage, nextPageToken),
  });

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Заявки на участие в событии</h1>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <DataTable
          columns={columns}
          data={data?.data ?? []}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data?.num_elements}
        />
      )}
    </div>
  );
};

export default ApplicationsPage;
