import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { Column } from "../types/Column";

// Define the types for the column and row data
type DataTableProps<T> = {
  columns: Column<T>[];
  data: T[];
  count?: number;
  rowsPerPage?: number;
  page?: number;
  handleChangePage?: (_: any, page: number) => void;
  handleChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  rowsPerPageOptions?: number[];
};

// Create a generic DataTable component
export function DataTable<T>({
  columns,
  data,
  count = 1,
  page,
  rowsPerPage = 5,
  handleChangePage = () => {},
  handleChangeRowsPerPage,
  rowsPerPageOptions,
}: DataTableProps<T>) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <TableCell key={columnIndex}>{column.render(item)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>{" "}
      {(page || handleChangeRowsPerPage || count) && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page ?? 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={(data) =>
            `Показано: с ${data.from} по ${data.to} из ${count}`
          }
          labelRowsPerPage="Строк на странице"
        />
      )}
    </TableContainer>
  );
}
