// BanUserModal.tsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type BanUserModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  text: string;
  deleteButton?: string;
};

const DeleteDialog: React.FC<BanUserModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  text,
  deleteButton = "Удалить",
}: BanUserModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title}
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", right: "10px", top: "10px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p>{text}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Отмена
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          {deleteButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
