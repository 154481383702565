import React, { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";

const CustomDrawerComponent = () => {
  const [minimized, setMinimized] = useState(false);

  const navigate = useNavigate();

  const toggleMinimized = () => {
    setMinimized(!minimized);
  };

  return (
    <div
      style={{
        width: minimized ? "56px" : "240px",
        height: "auto",
        backgroundColor: "#e5e5e5",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        transition: "width 0.3s",
      }}
    >
      <List component="nav" style={{ minWidth: minimized ? "56px" : "240px" }}>
        <ListItemButton onClick={toggleMinimized}>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          {!minimized && <ListItemText primary="Menu" />}
        </ListItemButton>
        {routes.map((route, index) => (
          <ListItemButton
            key={index}
            style={{ minHeight: "56px" }}
            onClick={() => navigate(route.href)}
          >
            <ListItemIcon>{route.icon}</ListItemIcon>
            {!minimized && <ListItemText primary={route.label} />}
          </ListItemButton>
        ))}
      </List>
    </div>
  );
};

export default CustomDrawerComponent;
