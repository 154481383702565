import axios from "axios";
import {CreatedEvent, EditableEvent, EventType} from "../pages/EventsPage/EventType";
import {levelsOptions, typeOptions} from "../pages/EventsPage/EventsPage";

export const fetchEvents = async (
  limit: number,
  nextPageToken?: string | null
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/event`,
    {
      params: {pageToken: nextPageToken, itemsPerPage: limit},
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};

export const deleteEvent = async (id: EventType['post']['ext_id']) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/event/${id}`,
    {
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};

export const updateEvent = async (payload: EditableEvent) => {
  const splitStartDate = payload.start_date.split('T')
  const splitEndDate = payload.end_date.split('T')

  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/event/${payload.id}`,
    {
      title: payload.title,
      text: payload.text,
      start_date: splitStartDate[0] + ' ' + splitStartDate[1] + ':00',
      end_date:  splitEndDate[0] + ' ' + splitEndDate[1] + ':00',
      type: payload.type,
      level: payload.level,
      subject_id: payload.subject_id
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`
      },
    }
  );
  return response.data;
};

export const createEvent = async (payload: CreatedEvent) => {
  const splitStartDate = payload.start_date.split('T')
  const splitEndDate = payload.end_date.split('T')

  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/event`,
    {
      title: payload.title,
      text: payload.text,
      start_date: splitStartDate[0] + ' ' + splitStartDate[1] + ':00',
      end_date:  splitEndDate[0] + ' ' + splitEndDate[1] + ':00',
      type: payload.type,
      level: payload.level,
      subject_id: payload.subject_id,
      attachments: payload.attachments
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`
      },
    }
  );
  return response.data;
};


export const getEditableObject = (event: EventType): EditableEvent => {
  const levelValue = levelsOptions.find(option => option.value === event.level)
  const typeValue = typeOptions.find(option => option.value === event.type)

  const timeStartSplit = event.start_date.split('T')[1].split(":")
  const startDate = event.start_date.split('T')[0] + 'T' + timeStartSplit[0] + ':' + timeStartSplit[1]

  const timeEndSplit = event.end_date.split('T')[1].split(":")
  const endDate = event.end_date.split('T')[0] + 'T' + timeEndSplit[0] + ':' + timeEndSplit[1]

  return {
    id: event.post.ext_id,
    title: event.post.title,
    text: event.post.text,
    start_date: startDate,
    end_date: endDate,
    type: typeValue?.label ?? "",
    level: levelValue?.label ?? "",
    subject_id: event.post.subject.text,
  }
}