import { useEffect } from "react";

export default function PrivacyPage() {
  useEffect(() => {
    document.title = "Политика конфиденциальности";
  }, []);
  return (
    <div
      style={{
        padding: "20px",
        textAlign: "justify",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1 style={{ textAlign: "center" }}>
        ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПРИ ИСПОЛЬЗОВАНИИ ИНТЕРНЕТ ПРИЛОЖЕНИЯ
        «ОРУЖЕЙНИК»
        <br /> ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ОРУЖЕЙНИК»
      </h1>
      <ol>
        <li>
          <h2>Назначение</h2>
          <p>
            Политика предназначена для информирования Вас о наших действиях по
            сбору, обработке и защите Ваших персональных данных для достижения
            нами заявленных целей обработки персональных данных в мобильном
            приложении «Оружейник» (далее — Приложение). Мы соблюдаем требования
            российского законодательства в области персональных данных. При
            обработке персональных данных мы придерживаемся принципов,
            изложенных в ст. 5 Федерального закона от 27.07.2006 г № 152-ФЗ «О
            персональных данных» (далее — 152-ФЗ). Просим Вас внимательно
            изучить Политику конфиденциальности (далее — Политика), чтобы
            понимать для достижения каких целей мы осуществляем обработку Ваших
            персональных данных, а также как Вы можете реализовать права, в
            отношении своих персональных данных, обрабатываемых нами.
            Использование Приложения означает Ваше безоговорочное согласие с
            настоящей Политикой и указанными в ней условиями обработки
            персональных данных, получаемой с Вашего устройства. В случае
            несогласия с этими условиями Вы должны воздержаться от использования
            Приложения. Если у Вас есть вопросы, связанные с Политикой, в том
            числе предложения по улучшению ее понимания и навигации или есть
            вопросы по обработке нами Ваших персональных данных и их защите, вы
            можете направить нам обращение по одному из способов, указанных в
            разделе «Контакты».
          </p>
        </li>
        <li>
          <h2>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ (СОКРАЩЕНИЯ)</h2>
          <p>
            Персональные данные (ПДн) — любая информация, относящаяся к прямо
            или косвенно определенному или определяемому физическому лицу
            (субъекту ПДн). Передача ПДн — это вид обработки, в результате
            которой осуществляется передача информации между сторонами обмена.
            Предоставление ПДн — действия, направленные на раскрытие ПДн
            определённому лицу или определённому кругу лиц. Распространение ПДн
            — действия, направленные на раскрытие ПДн неопределённому кругу лиц.
            Трансграничная передача ПДн — передача ПДн на территорию
            иностранного государства органу власти иностранного государства,
            иностранному физическому или иностранному юридическому лицу.
            Уничтожение ПДн — действия, в результате которых невозможно
            восстановить содержание ПДн в информационной системе ПДн и (или)
            результате которых уничтожаются материальные носители ПДн.
            Автоматизированная обработка ПДн— обработка ПДн с помощью средств
            вычислительной техники. Блокирование ПДн — временное прекращение
            обработки ПДн (за исключением случаев, если обработка необходима для
            уточнения ПДн). Обезличивание ПДн — действия, в результате которых
            становиться невозможным без использования дополнительной информации
            определить принадлежность ПДн конкретному субъекту ПДн. Обработка
            ПДн — любое действие (операция) или совокупность действий
            (операций), совершаемых с использованием средств автоматизации или
            без использования таких средств с ПДн, включая сбор, запись,
            систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передачу (распространение,
            предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение ПДн. Интернет-сервисы — сервисы, предоставляемые в сети
            Интернет пользователям, программам, системам с помощью функционала
            заложенного разработчиком сайта Оператора. Информационная система
            ПДн (ИСПДн) — совокупность содержащихся в базах данных ПДн и
            обеспечивающих их обработку информационных технологий и технических
            средств. Оператор — Общество с ограниченной ответственностью
            «ОРУЖЕЙНИК» (ОГРН 1227700266221, ИНН 9710098251, юридический адрес:
            109012, г. Москва, ВН. ТЕР. Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ТВЕРСКОЙ, УЛ.
            ИЛЬИНКА, Д. 4, ЭТ/ПОМ 2/63-66, КОМ. 19, фактический адрес: 109012,
            г. Москва, ВН. ТЕР. Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ТВЕРСКОЙ, УЛ. ИЛЬИНКА, Д.
            4, ЭТ/ПОМ 2/63-66, КОМ. 19 самостоятельно или совместно с другими
            лицами организующие и (или) осуществляющие обработку ПДн, а также
            определяющие цели обработки ПДн, состав ПДн, подлежащих обработке,
            действия (операции), совершаемые с ПДн. Биометрические ПДн —
            сведения, которые характеризуют физиологические и биологические
            особенности человека, на основании которых можно установить его
            личность и которые используются оператором для установления личности
            субъекта ПДн. Специальная категория ПДн — сведения касающиеся
            расовой, национальной принадлежности, политических взглядов,
            религиозных или философских убеждений, состояния здоровья, интимной
            жизни. ПДн, разрешенные субъектом ПДн для распространения — ПДн,
            доступ неограниченного круга лиц к которым предоставлен субъектом
            ПДн путем дачи согласия на обработку ПДн, разрешенных субъектом ПДн
            для распространения в порядке, 152-ФЗ.
          </p>
        </li>
        <li>
          <h2>СФЕРА ПРИМЕНЕНИЯ</h2>
          <p>
            Политика предназначена для информирования Вас о наших действиях по
            сбору, обработке и защите Ваших персональных данных для достижения
            нами заявленных целей обработки персональных данных в мобильном
            Приложении. Обработки персональных данных, к которым применяется
            Политика, перечислены в разделе «Для чего мы обрабатываем Ваши
            персональные данные».
          </p>
        </li>
        <li>
          <h2>СБОР ПЕРСОНАЛЬНЫХ ДАННЫХ НЕСОВЕРШЕННОЛЕТНИХ</h2>
          <p>
            Наше Приложение не предназначено для обработки персональных данных
            несовершеннолетних. Если у Вас есть основания полагать, что
            несовершеннолетний предоставил нам свои персональные данные через
            Приложение, то просим Вас сообщить нам об этом, написав на почту
            oruzejnik.rus@gmail.com.
          </p>
        </li>
        <li>
          <h2>ДЛЯ ЧЕГО МЫ ОБРАБАТЫВАЕМ ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ</h2>
          <ul style={{ listStyleType: "none" }}>
            <li>
              5.1. Цель обработки персональных данных в Приложении: Создание
              учетной записи для пользования сервисам Приложения, если Вы даете
              свое согласие на обработку персональных данных. Связь с Вами, в
              том числе направление уведомлений, запросов и информации,
              касающихся использования сервисов Приложения, оказания услуг, а
              также обработка запросов и заявок от Вас. Предоставление Вам
              персональных сервисов Приложения. Улучшение качества Приложения,
              удобства его использования, для разработки новых сервисов и услуг.{" "}
            </li>
            <li>
              5.2. Ваши персональные данные, которые обрабатываются для
              указанной цели: имя, фамилия, отчество; контактный телефон; город;
              регион.
            </li>{" "}
            <li>
              5.3. Информация предоставляемая на Ваше усмотрение: Данные,
              которые могут автоматически собираться в случае предоставления
              Приложению разрешения: геоданные (используются для автоматического
              определения Вашего местоположения для предоставления Вам
              справочного сервиса Приложения).
            </li>
            <li>
              5.4. Какие действия мы можем совершать с Вашими персональными
              данными: сбор; запись; систематизация; накопление; хранение;
              уточнение (обновление, изменение); извлечение; использование;
              передача (предоставление, доступ); блокирование; удаление;
              уничтожение.
            </li>
            <li>
              5.5. Законные основания для обработки Ваших персональных данных:
              согласие на обработку персональных данных.
            </li>
            <li>
              5.6. Как долго мы обрабатываем Ваши персональные данные: до
              момента удаления Вами своего аккаунта (учетной записи) в разделе
              Приложения «Профиль».
            </li>
          </ul>
        </li>
        <li>
          <h2> КАКОВЫ ВАШИ ПРАВА?</h2>
          <ul style={{ listStyleType: "none" }}>
            <li>
              6.1. Общая информация о Ваших правах. В соответствии с 152-ФЗ, Вы
              имеете следующие права при обработке персональных данных: право на
              доступ к персональным данным; право на уточнение персональных
              данных; право на обжалование наших действий или нашего
              бездействия; право на обжалование решений, принятых на основании
              исключительно автоматизированной обработки их персональных данных;
              право на отзыв согласия.{" "}
            </li>
            <li>
              6.2. Запрос дополнительной информации для идентификации субъекта
              персональных данных. В ходе реализации Ваших прав мы можем
              запрашивать дополнительную информацию, которая позволит нам
              достоверно идентифицировать Вас. Такая информация будет
              требоваться в редких случаях. Мы стараемся запрашивать минимально
              необходимый состав дополнительной информации для Вашей
              идентификации. Мы не сможем реализовать Ваши права, если в ответ
              на наш запрос Вы не предоставите дополнительную информацию для
              Вашей идентификации.
            </li>{" "}
            <li>
              6.3. Условия предоставления информации по Вашим правам.
              Информация, связанная с реализацией Ваших прав, предоставляется
              бесплатно. Если Ваш запрос является явно необоснованным или
              чрезмерным, в частности из-за его повторяющегося характера, мы
              можем отказаться от ответа, либо взимать плату за предоставление
              запрашиваемой информации.
            </li>
            <li>
              6.4. Право на доступ к Вашим персональным данным. Вы имеете право
              получить от нас достоверную информацию об обработке Ваших
              персональных данных, и, если это возможно, доступ к персональным
              данным и к следующей информации: подтверждение факта обработки
              персональных данных оператором; правовые основания и цели
              обработки персональных данных; цели и применяемые оператором
              способы обработки персональных данных; наименование и место
              нахождения оператора, сведения о лицах (за исключением работников
              оператора), которые имеют доступ к персональным данным или которым
              могут быть раскрыты персональные данные на основании договора с
              оператором или на основании федерального закона; обрабатываемые
              персональные данные, относящиеся к Вам, источник их получения;
              сроки обработки персональных данных, в том числе сроки их
              хранения; порядок осуществления Вами своих прав согласно 152-ФЗ;
              информацию об осуществленной или о предполагаемой трансграничной
              передаче данных; наименование или фамилию, имя, отчество и адрес
              лица, осуществляющего обработку персональных данных по поручению
              оператора, если обработка поручена или будет поручена такому лицу.
            </li>
            <li>
              6.5. Право на уточнение Ваших персональных данных. Мы принимаем
              усилия, направленные на то, чтобы выявлять неточные, неполные или
              устаревшие данные. Если данные являются неточными, неполными или
              устаревшими, вы имеете право потребовать от нас исправления
              данных, касающихся Вас, без лишнего промедления. После исправления
              Ваших персональных данных мы уведомим Вас об этом.
            </li>
            <li>
              6.6. Право на обжалование наших действий или нашего бездействия.
              Вы имеете право возражать против обработки своих персональных
              данных в ответ на наши действия или наше бездействия. Для этого Вы
              можете обратиться в территориальный орган Роскомнадзора в своем
              регионе.
            </li>
            <li>
              6.7. Право на обжалование решений, принятых на основании
              исключительно автоматизированной обработки персональных данных. Вы
              имеете право не подвергаться решению, основанному исключительно на
              автоматизированной обработке, включая профилирование, которое
              влечет за собой юридические последствия в отношении Вас или
              аналогичным образом существенно влияет на Вас. Вы вправе
              обратиться к нам для обжалования наших действий при принятии нами
              решений на основании исключительно автоматизированной обработки их
              персональных данных, если такие имеются.
            </li>
            <li>
              {" "}
              6.8. Право на отзыв согласия Если Ваши данные обрабатываются на
              основании согласия, то вы имеете право отозвать его.{" "}
            </li>
            <li>
              {" "}
              6.9 Как Вы можете реализовать свои права? Для реализации Ваших
              прав нами представлены несколько способов: написать запрос на наш
              адрес 109012, г. Москва, ВН. ТЕР. Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ТВЕРСКОЙ,
              УЛ. ИЛЬИНКА, Д. 4, ЭТ/ПОМ 2/63-66, КОМ. 19, фактический адрес:
              109012, г. Москва, ВН. ТЕР. Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ТВЕРСКОЙ, УЛ.
              ИЛЬИНКА, Д. 4, ЭТ/ПОМ 2/63-66, КОМ. 19, указав в нем сведения о
              документе, удостоверяющем Вашу личность или личность Вашего
              представителя (тип документа, серия и номер, кем и когда выдан),
              Ваше ФИО или ФИО представителя, информацию о взаимоотношениях с
              Вами, которые будут подтверждать факт обработки персональных
              данных нами, Вашу подпись или подпись Вашего представителя. Такой
              запрос может быть направлен на нашу электронную почту в форме
              электронного документа, подписанного согласно законодательства
              Российской Федерации об электронной подписи; также вы можете
              обратиться напрямую к нам по электронной почте
              oruzejnik.rus@gmail.com.{" "}
            </li>
            <li>
              {" "}
              6.10. Как и когда мы не можем реализовать Ваши права. Мы можем
              ограничить объем наших обязательств и Ваших прав (на доступ к
              данным, их уточнение, блокирование или удаление), в следующих
              случаях: обработка персональных данных, включая персональные
              данные, полученные в результате оперативно-розыскной,
              контрразведывательной и разведывательной деятельности,
              осуществляется в целях обороны страны, безопасности государства и
              охраны правопорядка; обработка персональных данных осуществляется
              органами, осуществившими задержание субъекта персональных данных
              по подозрению в совершении преступления, либо предъявившими
              субъекту персональных данных обвинение по уголовному делу, либо
              применившими к субъекту персональных данных меру пресечения до
              предъявления обвинения; за исключением предусмотренных
              уголовно-процессуальным законодательством Российской Федерации
              случаев, если допускается ознакомление подозреваемого или
              обвиняемого с такими персональными данными; обработка персональных
              данных осуществляется в соответствии с законодательством о
              противодействии легализации (отмыванию) доходов, полученных
              преступным путем, и финансированию терроризма; доступ субъекта
              персональных данных к его персональным данным нарушает права и
              законные интересы третьих лиц; обработка персональных данных
              осуществляется в случаях, предусмотренных законодательством
              Российской Федерации о транспортной безопасности, в целях
              обеспечения устойчивого и безопасного функционирования
              транспортного комплекса, защиты интересов личности, общества и
              государства в сфере транспортного комплекса от актов незаконного
              вмешательства. когда мы имеем законные основания для продолжения
              обработки Ваших персональных данных. Для реализации некоторых прав
              существуют особые условия, без выполнения которых право не может
              быть реализовано. Если мы не можем реализовать Ваше право по
              Вашему запросу, то мы пишем Вам мотивированный отказ.
            </li>
          </ul>
        </li>
        <li>
          <h2> Безопасность данных</h2>
          <p>
            Персональные данные, которые мы собираем и храним, считаются
            конфиденциальной информацией. Они защищены от потери, изменения или
            несанкционированного доступа согласно законодательству Российской
            Федерации в области персональных данных. Для этого мы применяем
            технические средства и организационные меры. Мы постоянно
            совершенствуем наши системы защиты данных.
          </p>
        </li>
        <li>
          <h2> Изменение политики</h2>
          <p>
            Мы оставляем за собой право вносить изменения в нашу Политику в
            любое время. Мы просим Вас регулярно просматривать все обновления
            нашей Политики. Мы будем направлять уведомления об изменениях
            Политики в случае, если такие изменения являются существенными,
            всеми доступными нам способами.
          </p>
        </li>
        <li>
          <h2>Контакты</h2>
          <p>
            Обращаем Ваше внимание, что указанные в данном пункте контакты
            используются только для взаимодействия по вопросам обработки и
            защиты Ваших персональных данных. Обращения, направленные Вами по
            указанным в Политике контактам, не связанные с защитой данных, не
            будут рассмотрены. Для вопросов, связанных с обработкой и защитой
            персональных данных, вы можете обратиться напрямую к нашему
            ответственному за организацию обработки персональных данных написав
            на oruzejnik.rus@gmail.com.
          </p>
        </li>
      </ol>
      <p>
        ООО «ОРУЖЕЙНИК» Юридический адрес (в соответствии с Учредительными
        документами) 109012, г. Москва, ВН. ТЕР. Г. МУНИЦИПАЛЬНЫЙ ОКРУГ
        ТВЕРСКОЙ, УЛ. ИЛЬИНКА, Д. 4, ЭТ/ПОМ 2/63-66, КОМ. 19 ИНН 9710098251 КПП
        771001001 ОГРН 1227700266221 Электронная почта: oruzejnik.rus@gmail.com
      </p>
    </div>
  );
}
