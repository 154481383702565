import axios from "axios";
import { ForumRequest, Post } from "../pages/ForumsPage/Forum";

const fetchForums = async (limit: number, nextPageToken?: string | null) => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/feed/forum/fetch?itemsPerPage=${limit}&PageToken=${nextPageToken ?? ""}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewForum = async (data: ForumRequest) => {
  const response = await axios.postForm(
    `${process.env.REACT_APP_BACKEND_URL}/forum`,
    { ...data, media: JSON.stringify(data.media) },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};
const updateForum = async (data: ForumRequest) => {
  const response = await axios.putForm(
    `${process.env.REACT_APP_BACKEND_URL}/forum`,
    { ...data, media: JSON.stringify(data.media) },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};
const updateForumStatus = async (data: { status: string; ext_id: string }) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/forum/status`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};
const removeForum = async (data: Pick<Post, "ext_id">) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/forum?extID=${data.ext_id}`,
    {
      data: { ext_id: data.ext_id },
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export {
  fetchForums,
  addNewForum,
  removeForum,
  updateForum,
  updateForumStatus,
};
