import React, {useState} from "react";
import {CircularProgress, IconButton} from "@mui/material";
import {DataTable} from "../../components/DataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import {Column} from "../../types/Column";
import {Comment} from "./Comment";
import DeleteDialog from "../../components/DeleteDialog";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {deleteComment, fetchComments} from "../../api/comments";


export const CommentsPage = () => {
  const queryClient = useQueryClient();

  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<null | Comment>(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleRemoveClick = (data: Comment) => {
    setCurrentdata(data);
    setRemoveModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setRemoveModalOpen(false);
  };

  const columns: Column<Comment>[] = [
    {
      label: "Автор (никнейм)",
      render: (comment) => <>{comment.author.nickname}</>,
    },
    {
      label: "Комментарий",
      render: (comment) => <>{comment.message}</>,
    },
    {
      label: "Дата создания",
      render: (comment) => <>{new Date(comment.created_at).toLocaleDateString()}</>,
    },
    {
      label: "Действия",
      render: (comment) => (
        <>
          <IconButton
            aria-label="block"
            onClick={() => handleRemoveClick(comment)}
          >
            <DeleteIcon/>
          </IconButton>
        </>
      ),
    },
  ];

  const {data, error, isLoading} = useQuery({
    queryKey: ["comments", rowsPerPage, nextPageToken],
    queryFn: () => fetchComments(rowsPerPage, nextPageToken),
  });

  const rejectComplaint = useMutation({
    mutationFn: (data: Comment) => deleteComment(data.ext_id),
    mutationKey: ["comments"],
    onSettled: () => queryClient.invalidateQueries({queryKey: ["comments"]}),
  });

  const handleConfirmRemove = () => {
    if (currentdata) {
      rejectComplaint.mutate(currentdata)
      handleClose()
    }
  }

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{paddingLeft: "20px", paddingRight: "10px"}}>
      <h1>Комментарии</h1>
      {isLoading ? <CircularProgress/> : <DataTable
        columns={columns}
        data={data?.data ?? []}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(event) => {
          setRowsPerPage(+event.target.value);
        }}
        page={page}
        handleChangePage={(event, newPage) => {
          setPage(newPage);
          if (newPage > page) {
            setNextPageToken(data.next_page_token);
            setTokens((prev) => [...prev, data.next_page_token]);
          } else {
            setNextPageToken(tokens[newPage - 1]);
          }
        }}
        count={
          data?.next_page_token
            ? (page + 1) * rowsPerPage + 1
            : (page + 1) * rowsPerPage
        }
      />}
      <DeleteDialog
        open={isRemoveModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmRemove}
        title="Удаление комментария"
        text={`Вы точно хотите удалить комментарий?`}
      />
    </div>
  );
};

