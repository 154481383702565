import React, { useState } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DataTable } from "../../components/DataTable";
import { Column } from "../../types/Column";
import { Complaint, ComplaintStatusEnum } from "./Complaint";
import DeleteDialog from "../../components/DeleteDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { changeComplaintStatus, fetchComplaints } from "../../api";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";

const statusList: {
  status: ComplaintStatusEnum;
  label: string;
}[] = [
  { status: ComplaintStatusEnum.ALL, label: "Все" },
  { status: ComplaintStatusEnum.CREATED, label: "Создано" },
  { status: ComplaintStatusEnum.REJECTED, label: "Отклонено" },
  {
    status: ComplaintStatusEnum.APPROVED,
    label: "Подтверждено",
  },
];

export const ComplaintsPage = () => {
  const queryClient = useQueryClient();

  const [selectedStatus, setSelectedStatus] = useState(ComplaintStatusEnum.ALL);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [currentComplaint, setCurrentComplaint] = useState<null | Complaint>(
    null
  );
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleCloseRejectModal = () => {
    setIsRejectModalOpen(false);
    setCurrentComplaint(null);
  };

  const handleCloseApproveModal = () => {
    setIsApproveModalOpen(false);
    setCurrentComplaint(null);
  };

  const handleRejectModalOpen = (complaint: Complaint) => {
    setCurrentComplaint(complaint);
    setIsRejectModalOpen(true);
  };

  const handleOpenAppoveModal = (complaint: Complaint) => {
    setCurrentComplaint(complaint);
    setIsApproveModalOpen(true);
  };

  const columns: Column<Complaint>[] = [
    {
      label: "id",
      render: (complaint) => <>{complaint.id}</>,
    },
    {
      label: "Создано",
      render: (complaint) => <>{complaint.nickname}</>,
    },
    {
      label: "Жалоба на аккаунт",
      render: (complaint) => <>{complaint.complained_nickname}</>,
    },
    {
      label: "Статус",
      render: (complaint) => (
        <>
          {
            statusList.find((status) => status.status === complaint.status)
              ?.label
          }
        </>
      ),
    },
    {
      label: "Действия",
      render: (complaint) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleRejectModalOpen(complaint)}
          >
            <BlockIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleOpenAppoveModal(complaint)}
          >
            <CheckIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const { data, error, isLoading } = useQuery({
    queryKey: ["complaints", rowsPerPage, selectedStatus, nextPageToken],
    queryFn: () => fetchComplaints(rowsPerPage, selectedStatus, nextPageToken),
  });

  const rejectComplaint = useMutation({
    mutationFn: (data: Complaint) =>
      changeComplaintStatus(data, ComplaintStatusEnum.REJECTED),
    mutationKey: ["complaints"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["complaints"] }),
  });

  const approveComplaint = useMutation({
    mutationFn: (data: Complaint) =>
      changeComplaintStatus(data, ComplaintStatusEnum.APPROVED),
    mutationKey: ["complaints"],
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["complaints"] }),
  });

  const handleConfirmReject = () => {
    if (currentComplaint) {
      rejectComplaint.mutate(currentComplaint);
      handleCloseRejectModal();
    }
  };

  const handleConfirmApprove = () => {
    if (currentComplaint) {
      approveComplaint.mutate(currentComplaint);
      handleCloseApproveModal();
    }
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Жалобы</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}>
        <FormControl fullWidth>
          <InputLabel>Статус</InputLabel>
          <Select
            value={selectedStatus}
            label="Статус"
            onChange={(e) =>
              setSelectedStatus(e.target.value as ComplaintStatusEnum)
            }
          >
            {statusList.map((status) => (
              <MenuItem key={status.status} value={status.status}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <DataTable
          columns={columns}
          data={data?.complained_users ?? []}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data.num_elements}
        />
      )}
      <DeleteDialog
        open={isRejectModalOpen}
        onClose={handleCloseRejectModal}
        onConfirm={handleConfirmReject}
        title="Отклонение жалобы"
        deleteButton="Отклонить"
        text={`Вы точно хотите отклонить жалобу?`}
      />

      <DeleteDialog
        open={isApproveModalOpen}
        onClose={handleCloseApproveModal}
        onConfirm={handleConfirmApprove}
        title="Подтверждение жалобы"
        deleteButton="Подтвердить"
        text={`Вы точно хотите подтвердить жалобу?`}
      />
    </div>
  );
};

export default ComplaintsPage;
