import { Alert, Button, Card, CardContent, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const { login, isAuthenticated, error } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    document.title = "Авторизаиця в Оружейник";
  }, [isAuthenticated, navigate]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  return (
    <main
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          login({ username, password });
        }}
      >
        {" "}
        <Card sx={{ minWidth: 275, maxWidth: 500 }}>
          <CardContent
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              label={"Логин"}
              id="username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <TextField
              label={"Пароль"}
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <Button type="submit" variant="outlined">
              Войти
            </Button>
            {error && <Alert severity="error">{error}</Alert>}
          </CardContent>
        </Card>
      </form>
    </main>
  );
}
