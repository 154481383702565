import axios from "axios";

export const fetchEventApplication = async (
  limit: number,
  nextPageToken?: string | null
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/event_application`,
    {
      params: {pageToken: nextPageToken, itemsPerPage: limit},
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};
