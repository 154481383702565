import axios from "axios";
import { Law } from "../pages/LawsPage/Law";

const fetchLaws = async (limit: number, nextPageToken?: string | null) => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/feed/law/fetch?itemsPerPage=${limit}&PageToken=${nextPageToken ?? ""}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

const addNewLaw = async (data: Law) => {
  const response = await axios.postForm(
    `${process.env.REACT_APP_BACKEND_URL}/law`,
    { ...data, media: JSON.stringify(data.media) },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};

const updateLaw = async (
  data: Pick<
    Law,
    "ext_id" | "title" | "text" | "subject_id" | "attachments" | "media"
  >
) => {
  const response = await axios.putForm(
    `${process.env.REACT_APP_BACKEND_URL}/law`,
    { ...data, media: JSON.stringify(data.media) },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};

const removeLaw = async (data: Pick<Law, "ext_id">) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/law`,
    {
      data: { ext_id: data.ext_id },
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    }
  );
  return response.data;
};

export { fetchLaws, addNewLaw, removeLaw, updateLaw };
