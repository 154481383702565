import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { FAQ, FAQRequest } from "./FAQ";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AddModal from "../../components/AddModal";
import { Subject } from "../SubjectsPage/Subject";
import { addNewFaq, fetchFaq, removeFaq, updateFaq } from "../../api";
import { fetchSubjects } from "../../api";
import DeleteDialog from "../../components/DeleteDialog";

const FAQPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isBanModalOpen, setBanModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState<FAQ | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (userData: FAQ) => {
    setCurrentData(userData);
    setEditModalOpen(true);
  };

  const handleBanClick = (userData: FAQ) => {
    setCurrentData(userData);
    setBanModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setBanModalOpen(false);
    setAddModalOpen(false);
  };

  // Handler for when the modal save action is performed
  const handleSave = (updatedData: FAQ) => {
    changeUser.mutate({
      ...updatedData,
      subject_id: subjects?.find(
        (subject: Subject) => subject.text === updatedData.subject_id
      )?.ext_id,
    });
    setEditModalOpen(false);
  };
  
  const handleConfirmBan = () => {
    if (!currentData) return;
    deleteUser.mutate(currentData.id);
    setBanModalOpen(false);
  };

  const handleAdd = (updatedData: FAQRequest) => {
    addUser.mutate({
      ...updatedData,
      subject_id: subjects?.find(
        (subject: Subject) => subject.text === updatedData.subject_id
      )?.ext_id,
    });
    handleClose();
  };
  const columns: Column<FAQ>[] = [
    {
      label: "id",
      render: (question) => <>{question.id}</>,
    },
    {
      label: "Вопрос",
      render: (question) => <>{question.question}</>,
    },
    {
      label: "Ответ",
      render: (question) => <>{question.answer}</>,
    },
    {
      label: "Действия",
      render: (question) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(question)}
            key={question.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleBanClick(question)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();

  const { data: subjects } = useQuery({
    queryKey: ["subjects"],
    queryFn: () => fetchSubjects(),
  });

  const { data, error, isLoading } = useQuery({
    queryKey: ["faq", rowsPerPage, nextPageToken],
    queryFn: () => fetchFaq(rowsPerPage, nextPageToken),
  });

  const addUser = useMutation({
    mutationFn: addNewFaq,
    mutationKey: ["faq"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["faq"] }),
  });

  const deleteUser = useMutation({
    mutationFn: removeFaq,
    mutationKey: ["faq"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["faq"] }),
  });

  const changeUser = useMutation({
    mutationFn: updateFaq,
    mutationKey: ["faq"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["faq"] }),
  });

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>FAQ</h1>
      <div style={{ marginBottom: "20px" }}>
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>
      {data && (
        <DataTable
          columns={columns}
          data={data.data}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={
            data?.next_page_token
              ? (page + 1) * rowsPerPage + 1
              : (page + 1) * rowsPerPage
          }
        />
      )}{" "}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить вопрос"
        defaultState={{
          question: "",
          answer: "",
          subject_id: "",
        }}
        columns={[
          { label: "Вопрос", key: "question", editable: true },
          { label: "Ответ", key: "answer", editable: true },
          {
            label: "Тема",
            key: "subject_id",
            editable: true,
            type: "select",
            options: subjects?.map((subject: Subject) => subject.text) ?? [],
          },
        ]}
        onSave={handleAdd}
      />
      {currentData && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование вопроса"
          data={{ ...currentData, subject_id: currentData.tag?.text ?? "" }}
          columns={[
            { label: "Вопрос", key: "question", editable: true },
            { label: "Ответ", key: "answer", editable: true },
            {
              label: "Тема",
              key: "subject_id",
              editable: true,
              type: "select",
              options: subjects?.map((subject: Subject) => subject.text) ?? [],
            },
          ]}
          onSave={handleSave}
        />
      )}
      <DeleteDialog
        open={isBanModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmBan}
        title="Удаление вопроса"
        text={`Вы точно хотите удалить вопрос
        ${currentData?.question}?`}
      />
    </div>
  );
};

export default FAQPage;
