import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import { StopWord } from "./StopWord";
import DeleteDialog from "../../components/DeleteDialog";
import {
  addNewStopWord,
  fetchStopWords,
  removeStopWord,
  updateStopWord,
} from "../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AddModal from "../../components/AddModal";

const StopWordsPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [currentdata, setCurrentdata] = useState<StopWord | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (data: StopWord) => {
    setCurrentdata(data);
    setEditModalOpen(true);
  };

  const handleRemoveClick = (data: StopWord) => {
    setCurrentdata(data);
    setRemoveModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setRemoveModalOpen(false);
    setAddModalOpen(false);
  };

  const handleSave = (updateddata: StopWord) => {
    changeStopWord.mutate(updateddata);
    handleClose();
  };

  const handleAdd = (updateddata: StopWord) => {
    data.id = uuidv4();
    addStopWord.mutate([updateddata]);
    handleClose();
  };
  const handleConfirmRemove = () => {
    if (!currentdata) return;
    console.log(` ${currentdata?.word} has been deleted.`);
    deleteStopWord.mutate({ id: currentdata?.id });
    handleClose();
  };

  const columns: Column<StopWord>[] = [
    {
      label: "id",
      render: (stopWord) => <>{stopWord.id}</>,
    },
    {
      label: "Текст",
      render: (stopWord) => <>{stopWord.word}</>,
    },
    {
      label: "Дата",
      render: (stopWord) => (
        <>{new Date(stopWord.created_at ?? "").toLocaleDateString()}</>
      ),
    },
    {
      label: "Действия",
      render: (stopWord) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(stopWord)}
            key={stopWord.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="block"
            onClick={() => handleRemoveClick(stopWord)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["stopWords", rowsPerPage, nextPageToken],
    queryFn: () => fetchStopWords(rowsPerPage, nextPageToken),
  });
  const addStopWord = useMutation({
    mutationFn: addNewStopWord,
    mutationKey: ["stopWords"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["stopWords"] }),
  });
  const changeStopWord = useMutation({
    mutationFn: updateStopWord,
    mutationKey: ["stopWords"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["stopWords"] }),
  });
  const deleteStopWord = useMutation({
    mutationFn: removeStopWord,
    mutationKey: ["stopWords"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["stopWords"] }),
  });
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Стоп-слова</h1>
      <div style={{ marginBottom: "20px", display: "flex" }}>
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>{" "}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить стоп-слово"
        columns={[{ label: "Стоп-слово", key: "word", editable: true }]}
        onSave={handleAdd}
      />
      {data?.stop_word && (
        <DataTable
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          columns={columns}
          data={data.stop_word}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={
            data?.next_page_token
              ? (page + 1) * rowsPerPage + 1
              : (page + 1) * rowsPerPage
          }
        />
      )}
      {currentdata && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование стоп-слова"
          data={currentdata}
          columns={[{ label: "Название", key: "word", editable: true }]}
          onSave={handleSave}
        />
      )}
      {currentdata && (
        <DeleteDialog
          open={isRemoveModalOpen}
          onClose={handleClose}
          onConfirm={handleConfirmRemove}
          title="Удаление стоп-слова"
          text={`Вы точно хотите удалить стоп-слово
        ${currentdata.word}?`}
        />
      )}
    </div>
  );
};

export default StopWordsPage;
function uuidv4(): number {
  return Math.floor(Math.random() * 1000000000);
}
